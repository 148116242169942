import React from 'react';

import useTranslation from '../../hooks/use_translation';

const WaitTooltip = () => {
	const { t } = useTranslation('component_wait_tooltip');

	return (
		<span data-bs-placement="top" data-bs-toggle="tooltip" title={t('t1')}>
			{'*'}
		</span>
	);
};

export default WaitTooltip;
