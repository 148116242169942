import { Statsig } from 'statsig-react';

function sendDataLayerEvent(events) {
	if (!window || !window.dataLayer || !events?.length) return null;

	for (const event of events) {
		window.dataLayer.push(event);
	}
}

function sendStatsigEvent(event, value, metadata) {
	if (!Statsig?.logEvent) return;
	Statsig.logEvent(event, value, metadata);
}

export function createEvent(event, data = {}) {
	if (event == 'add_to_cart') {
		const items = generateItems({ items: data?.items });

		sendDataLayerEvent([
			{ ecommerce: null },
			{
				ecommerce: {
					currency: data.currency,
					items,
					value: data.value,
				},
				event,
			},
		]);

		if (items?.length) {
			sendStatsigEvent(event, items[0].item_name, items[0]);
		}
	} else if (event == 'begin_checkout') {
		const items = generateItems({ items: data?.items });

		sendDataLayerEvent([
			{ ecommerce: null },
			{
				ecommerce: {
					currency: data.currency,
					items,
					value: data.value,
				},
				event,
			},
		]);

		sendStatsigEvent(event, data.value);
	} else if (event == 'button_click') {
		sendDataLayerEvent([
			{ button_click: null, ecommerce: null },
			{
				button_click: data,
				event,
			},
		]);

		sendStatsigEvent(event, 1, data);
	} else if (event == 'button_tag') {
		sendDataLayerEvent([
			{ button_tag: null, ecommerce: null },
			{
				button_tag: data,
				event,
			},
		]);

		sendStatsigEvent(event, 1, data);
	} else if (event == 'content_group') {
		sendDataLayerEvent([
			{
				content_group: data.id,
			},
		]);
	} else if (event.substring(0, 7) == 'klarna_') {
		sendDataLayerEvent([
			{ ecommerce: null, klarna: null },
			{
				event,
				klarna: data,
			},
		]);
	} else if (event == 'purchase') {
		const items = generateItems({
			channel: data?.channel,
			discount_code: data?.discount_code,
			items: data?.items,
		});

		sendDataLayerEvent([
			{ ecommerce: null, order: null },
			{
				ecommerce: {
					coupon: data?.discount_code?.code,
					currency: data.currency,
					items,
					transaction_id: data.transaction_id,
					value: data.value,
				},
				event,
				order: {
					channel_id: data?.channel?.id,
				},
			},
		]);

		sendStatsigEvent(event, data.value, {
			coupon: data?.discount_code?.code,
			transaction_id: data.transaction_id,
		});
	} else if (event == 'remove_from_cart') {
		const items = generateItems({ items: data?.items });

		sendDataLayerEvent([
			{ ecommerce: null },
			{
				ecommerce: {
					currency: data.currency,
					items,
					value: data.value,
				},
				event,
			},
		]);

		if (items?.length) {
			sendStatsigEvent(event, items[0].item_name, items[0]);
		}
	} else if (event == 'search') {
		sendDataLayerEvent([
			{
				event,
				search_term: data.search_term,
			},
		]);

		sendStatsigEvent(event, data.search_term);
	} else if (event == 'view_cart') {
		const items = generateItems({ items: data?.items });

		sendDataLayerEvent([
			{ ecommerce: null },
			{
				ecommerce: {
					currency: data.currency,
					items,
					value: data.value,
				},
				event,
			},
		]);

		sendStatsigEvent(event, items?.length);
	} else if (event == 'view_item') {
		const items = generateItems({ items: data?.items });

		sendDataLayerEvent([
			{ ecommerce: null },
			{
				ecommerce: {
					currency: data.currency,
					items,
					value: data.value,
				},
				event,
			},
		]);

		if (items?.length) {
			sendStatsigEvent(event, items[0].item_name, items[0]);
		}
	}
}

export function generateItems({ channel, discount_code, items }) {
	let out = [];
	if (!items) return out;

	for (const item of items) {
		const tmp = {
			affiliation: channel?.symbol ?? undefined,
			coupon: discount_code?.code ?? undefined,
			item_id: item.product_id ?? undefined,
			item_list_name: item.item_list_name ?? undefined,
			item_name: item.title ?? 'No product name',
			price: item.amount ?? 0,
			quantity: item.quantity ?? 1,
		};
		out.push(tmp);
	}

	return out;
}
