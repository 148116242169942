'use strict';

import {
	useTranslation as i18nextUseTranslation,
	useI18next,
} from 'gatsby-plugin-react-i18next';

function useTranslation(key) {
	const { language, languages, navigate, originalPath } = useI18next();
	const { t } = i18nextUseTranslation(null, {
		keyPrefix: key,
	});

	return {
		language: language,
		languages,
		navigate,
		originalPath,
		t,
	};
}

export default useTranslation;
