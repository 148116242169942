import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { getVariantByLanguage } from '../../utils/helpers';
import Link from './link';

const GroupLink = forwardRef(function GroupLink(
	{
		article_id,
		children,
		groups,
		location_id,
		marker_id,
		page,
		product_brand_id,
		product_id,
		slug,
		symptom_id,
		...props
	},
	ref,
) {
	const language = 'sv';
	const result = useStaticQuery(graphql`
		query GroupLink {
			allArticle {
				nodes {
					article_id
					article_variants {
						language {
							code
						}
						slug
					}
					groups
				}
			}

			allGroup {
				nodes {
					group_id
					group_variants {
						language {
							code
						}
						slug
					}
				}
			}

			allNewLocation {
				nodes {
					groups
					location_id
					location_variants {
						language {
							code
						}
						slug
					}
				}
			}

			allMarker {
				nodes {
					groups
					marker_id
					marker_variants {
						language {
							code
						}
						slug
					}
				}
			}

			allNewProduct {
				nodes {
					groups
					product_id
					product_variants {
						language {
							code
						}
						slug
					}
				}
			}

			allProductBrand {
				nodes {
					product_brand_id
					product_brand_variants {
						language {
							code
						}
						slug
					}
				}
			}
		}
	`);
	let variants = null;
	let to = `/${page}/`;

	if (groups && groups[0] && page != 'vardtjanster') {
		// Groups is only using swedish at the moment
		const group = result.allGroup.nodes.find(
			(value) => value.group_id == groups[0],
		);

		if (group) {
			const variant = getVariantByLanguage(language, group.group_variants);
			if (variant) {
				to = `${to}${variant.slug}/`;
			}
		}
	}

	if (article_id) {
		const id = parseInt(article_id);
		const match = result.allArticle.nodes.find(
			(value) => value.article_id == id,
		);
		variants = match?.article_variants;
	} else if (location_id) {
		const id = parseInt(location_id);
		const match = result.allNewLocation.nodes.find(
			(value) => value.location_id == id,
		);
		variants = match?.location_variants;
	} else if (marker_id) {
		const id = parseInt(marker_id);
		const match = result.allMarker.nodes.find((value) => value.marker_id == id);
		variants = match?.marker_variants;
	} else if (product_id) {
		const id = parseInt(product_id);
		const match = result.allNewProduct.nodes.find(
			(value) => value.product_id == id,
		);
		variants = match?.product_variants;
	} else if (product_brand_id) {
		const id = parseInt(product_brand_id);
		const match = result.allProductBrand.nodes.find(
			(value) => value.product_brand_id == id,
		);
		variants = match?.product_brand_variants;
	} else if (symptom_id) {
		const id = parseInt(symptom_id);
		const match = result.allArticle.nodes.find(
			(value) => value.article_id == id,
		);
		variants = match?.article_variants;
	}

	if (variants) {
		const variant = getVariantByLanguage(language, variants);
		if (variant) {
			to = `${to}${variant.slug}/`;
		}
	}

	if (slug) {
		to = `${to}${slug}/`;
	}

	return (
		<Link {...props} ref={ref} to={to}>
			{children}
		</Link>
	);
});

GroupLink.propTypes = {
	article_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	children: PropTypes.any,
	groups: PropTypes.array,
	location_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	marker_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	page: PropTypes.string,
	product_brand_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	product_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	slug: PropTypes.string,
	symptom_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default GroupLink;
