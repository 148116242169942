import { Trans as I18nextTrans } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { handleTagComponent } from '../../utils/helpers';
import ArticleTag from '../article/tag';
import Link from '../framework/link';
import WaitTooltip from '../information/wait_tooltip';

const Trans = ({ defaults, i18nKey, t, values }) => {
	return (
		<I18nextTrans
			components={{
				Link: <Link />,
				Tag: <ArticleTag />,
				WaitTooltip: <WaitTooltip />,
				a: <a />,
				b: <b />,
				br: <br />,
				div: <div />,
				em: <em />,
				figcaption: <figcaption />,
				figure: <figure />,
				h1: <h1 />,
				h2: <h2 />,
				h3: <h3 />,
				h4: <h4 />,
				h5: <h5 />,
				hr: <hr />,
				i: <i />,
				iframe: <iframe />,
				img: <img />,
				li: <li />,
				ol: <ol />,
				p: <p />,
				small: <small />,
				strong: <strong />,
				table: <table />,
				tbody: <tbody />,
				td: <td />,
				th: <th />,
				thead: <thead />,
				tr: <tr />,
				ul: <ul />,
			}}
			defaults={handleTagComponent(defaults)}
			i18nKey={t(i18nKey)}
			shouldUnescape={true}
			t={t}
			values={values}
		/>
	);
};

Trans.propTypes = {
	defaults: PropTypes.string,
	i18nKey: PropTypes.string,
	t: PropTypes.func.isRequired,
	values: PropTypes.object,
};

export default Trans;
