import {
	I18nextContext,
	Link as I18nextLink,
} from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React, { forwardRef, useContext } from 'react';

const Link = forwardRef(function Link({ children, ...options }, ref) {
	const context = useContext(I18nextContext);

	if (
		!options.to ||
		options.to.substring(0, 1) === '#' ||
		options.to.substring(0, 4) === 'http' ||
		options.to.substring(0, 7) === 'mailto:' ||
		options.to.substring(0, 4) === 'tel:' ||
		options.target == '_blank'
	) {
		options.href = options.to || '';
		options.to = undefined;
		return <a {...options}>{children}</a>;
	}

	if (options.to && options.to.indexOf('/boka') !== -1) {
		const getLanguagePath = (language) => {
			return context.generateDefaultLanguagePage ||
				language !== context.defaultLanguage
				? `/${language}`
				: '';
		};
		options.to = `${getLanguagePath(context.language)}${options.to}`;
	}

	return (
		<I18nextLink ref={ref} {...options}>
			{children}
		</I18nextLink>
	);
});

Link.propTypes = {
	children: PropTypes.any,
};

export default Link;
