import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { createContext, useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const default_values = {
	getChannelId: () => {},
	setChannel: () => {},
};
const local_storage_key = 'channel';

export const ChannelContext = createContext(default_values);

export const ChannelProvider = ({ children, location }) => {
	const { channel: channel_parameter } = queryString.parse(location.search);
	const [channel, setChannel] = useLocalStorageState(local_storage_key);

	const getChannelId = () => {
		if (channel?.id) {
			const check = Number(channel.id);
			if (!isNaN(check)) return channel.id;
		}
		return undefined;
	};

	useEffect(() => {
		if (channel?.id == channel_parameter) {
			return;
		}

		setChannel((current) => {
			if (channel_parameter && current?.id != channel_parameter) {
				current = { id: channel_parameter, time: new Date().getTime() };
			}
			return current;
		});
	}, [channel, channel_parameter, setChannel]);

	return (
		<ChannelContext.Provider
			value={{
				getChannelId,
				setChannel,
			}}
		>
			{children}
		</ChannelContext.Provider>
	);
};

ChannelProvider.propTypes = {
	children: PropTypes.object,
	location: PropTypes.object,
};
