import HttpClient from '@zample-com/fetch/http-client';

const httpClient = new HttpClient({
	app_id: process.env.ZAMPLE_APP_ID,
	app_url: process.env.ZAMPLE_API_URL,
	queries: {
		discount_code: {
			_defaults: { base_url: '/v1/shop/discount_code' },
			check: {
				method: 'POST',
				url: `/check`,
			},
		},
		gift_card: {
			_defaults: { base_url: '/v1/shop/gift_card' },
			create: {
				method: 'POST',
				url: `/create`,
			},
			list: {
				url: `/list`,
			},
		},
		order: {
			_defaults: { base_url: '/v1/shop/order' },
			check: {
				url: `/check`,
			},
			create: {
				method: 'POST',
				url: `/create`,
			},
		},
		shop: {
			_defaults: { base_url: '/v1/shop' },
			cart: {
				method: 'POST',
				url: `/cart/list`,
			},
			empty: {
				method: 'POST',
				url: `/cart/empty`,
			},
			item_add: {
				method: 'POST',
				url: `/cart/item/add`,
			},
			item_remove: {
				method: 'POST',
				url: `/cart/item/remove`,
			},
			replace: {
				method: 'POST',
				url: `/cart/replace`,
			},
			share: {
				method: 'POST',
				url: `/cart/share`,
			},
		},
		subscription: {
			_defaults: { base_url: '/v1/shop/subscription' },
			add: {
				method: 'POST',
				url: `/add`,
			},
		},
		user: {
			_defaults: { base_url: '/v1/user' },
			get: {
				method: 'GET',
				url: `/get`,
			},
			update: {
				method: 'POST',
				url: `/update`,
			},
		},
	},
});

export default httpClient;
