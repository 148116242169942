import { useRequest as zampleUseRequest } from '@zample-com/fetch/swr';

import httpClient from '../libs/http-client';

function useRequest(query, options = { data: null, params: {} }) {
	options.httpClient = httpClient;
	return zampleUseRequest(query, options);
}

export default useRequest;
