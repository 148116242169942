import { useMutate as zampleUseMutate } from '@zample-com/fetch/swr';

import httpClient from '../libs/http-client';

function useMutate(query, options = { params: {} }) {
	options.httpClient = httpClient;
	return zampleUseMutate(query, options);
}

export default useMutate;
