import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { Suspense as ReactSuspense, useEffect, useState } from 'react';

import useTranslation from '../../hooks/use_translation';
import Trans from '../translation/trans';

const Suspense = ({ children, fallback, fallback_error, tags = {} }) => {
	const [show, setShow] = useState(false);
	const { t } = useTranslation('component_suspense');

	useEffect(() => {
		setShow(true);
	}, []);

	if (!fallback) fallback = <></>;
	if (!fallback_error)
		fallback_error = (
			<div>
				<Trans i18nKey="t1" t={t} />
			</div>
		);

	return (
		<>
			{show ? (
				<Sentry.ErrorBoundary
					beforeCapture={(scope) => {
						if (tags && Object.keys(tags)?.length) {
							for (const [key, value] of Object.entries(tags)) {
								scope.setTag(key, value);
							}
						}

						scope.setExtra({ suspense: true });
					}}
					fallback={fallback_error}
				>
					<ReactSuspense fallback={fallback}>{children}</ReactSuspense>
				</Sentry.ErrorBoundary>
			) : null}
		</>
	);
};

Suspense.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	fallback: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	fallback_error: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	tags: PropTypes.object,
};

export default Suspense;
