import * as Sentry from '@sentry/react';

const isProduction = process.env.NODE_ENV == 'production';

if (isProduction) {
	Sentry.init({
		dsn: process.env.GATSBY_SENTRY_DSN,
		environment: isProduction ? 'production' : 'development',
		//integrations: [Sentry.captureConsoleIntegration()],
		release: process.env.CF_PAGES_COMMIT_SHA ?? undefined,
		replaysOnErrorSampleRate: isProduction ? 1.0 : 0,
		replaysSessionSampleRate: isProduction ? 0.1 : 0,
		tracePropagationTargets: ['localhost', /^https:\/\/api.zample\.com\//],
		tracesSampleRate: isProduction ? 1.0 : 0,
	});
}
